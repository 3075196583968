import React from 'react';
import { defaultNavbarLinks } from '../../constants/constants';
import Navbar from '../Hero/Navbar/Navbar';
import classes from './DisclaimerHero.module.scss';

const DisclaimerHero = () => {
	return (
	  <div className={classes.wrapper}>
		  <Navbar sticky={false} links={defaultNavbarLinks} />
	  </div>
	);
  }

  export default DisclaimerHero
