import classes from './index.module.scss';
import React from 'react'
import { Helmet } from 'react-helmet';

import DisclaimerHero from '../../components/DisclaimerHero/DisclaimerHero';
import externalLinks from '../../utils/externalLinks';
import Footer from '../../components/Footer/Footer';



const DisclaimerPage = () => (
	<div id="investor-container" >
		<Helmet title="HSE - Disclaimer" />
		<DisclaimerHero />
		<div className={['wrapper', classes.DisclaimerWrapper].join(' ')} >
			<h2>Disclaimer</h2>
			<section>
				<p>The terms and conditions of this disclaimer apply to the use of the information available on this website as operated by Home Shopping Europe GmbH (“HSE”). The registered office of HSE is Münchener Strasse 101h, 85737 Ismaning, Germany.</p>
				<p>This website and the reports, financial statements and other documents available for downloading therefrom are informative in nature and do not constitute an offer of securities to the public within the meaning of Regulation (EU)2017/1129 of the European Parliament and of the Council, nor do they constitute a solicitation to make such an offer.</p>
				<p>The information on this website and in the reports, financial statements and other documents available on this website do not constitute an offer of securities or a solicitation to make such an offer, and may not be used for such purposes, in the United States or any other country or jurisdiction in which such an offer or solicitation is unlawful, or in respect of any person in relation to whom the making of such an offer or solicitation is unlawful. Everyone using this website and the documents available thereon, should acquaint themselves with and adhere to the applicable local legislation. Any securities referred to in the information furnished on this website have not been and will not be registered under the U.S. Securities Act of 1933, as amended (the “U.S. Securities Act”) and may be offered or sold in the United States only pursuant to an exemption from such registration.</p>
				<p>The information on this website and in the reports, financial statements and other documents available on this website is, unless expressly stated otherwise, not intended to be available to any person in the United States or any “U.S. person” (as such terms are defined in Regulation S of the U.S. Securities Act) other than “qualified institutional buyers” as defined in Rule 144A under the U.S. Securities Act.</p>
				<p>The information on this website is confidential and its distribution in certain jurisdictions may be restricted by law. Therefore, it must not be published or reproduced (in whole or in part) or disclosed by its recipients to any other person for any purpose without HSE’s consent.</p>
				<p>This information on this website includes “forward-looking statements.” These statements contain the words “anticipate,” “believe,” “intend,” “estimate,” “expect” and words of similar meaning. All statements other than statements of historical facts included in the information on this website, including, without limitation, those regarding the HSE’s financial position, business strategy, plans and objectives of management for future operations are forward-looking statements. By their nature, such forward-looking statements involve known and unknown risks, uncertainties and other important factors that could cause the actual results, performance or achievements of HSE to be materially different from results, performance or achievements expressed or implied by such forward-looking statements. These forward-looking statements speak only as at the date of this Presentation.</p>
				<p>The information offered on this website does not constitute investment advice, nor does it constitute an investment recommendation in respect of any financial instrument.</p>
				<p>Visitors to this website cannot derive any rights from this disclaimer or the information on the website and in the reports, financial statements and other documents. The information offered may be changed or terminated, or access thereto limited, without further notice. HSE and its affiliated companies accept no liability whatsoever for any damages (including lost profits) ensuing from use of the website, the availability thereof or the accuracy or completeness and of the information available on this website.</p>
				<p>This website and disclaimer are governed by the laws of Germany. All disputes arising from this disclaimer shall be submitted exclusively to the competent court in Munich.</p>
				<p>By clicking on “I agree to the Disclaimer and Privacy Policy” you accept the terms and conditions as set out above and confirm that you are bound by any restrictions that may be contained in the reports, financial statements and other documents available on this website.</p>
			</section>

		</div>

		<Footer
      links={[
        {
          text: 'ONLINESHOP',
          href: externalLinks.onlineshop,
          target: '_self',
        },
        {
          text: 'KARRIERE',
          href: externalLinks.karriere,
          target: '_self',
        },
        {
          text: 'Kontakt',
          href: externalLinks.kontakt,
          target: '_self',
        },
        {
          text: 'Investor Relations',
          href: externalLinks.investor,
          target: '_self',
        },
      ]}
      socialMediaLinks={[
        {
          icon: {
            src: require('assets/icons/facebook.svg'),
            alt: 'Facebook page',
          },
          href: externalLinks.facebook,
        },
        {
          icon: {
            src: require('assets/icons/instagram.svg'),
            alt: 'Instagram profile',
          },
          href: externalLinks.instagram,
        },
        {
          icon: {
            src: require('assets/icons/pinterest.svg'),
            alt: 'Pinterest profile',
          },
          href: externalLinks.pinterest,
        },
        {
          icon: {
            src: require('assets/icons/youtube.svg'),
            alt: 'Youtube channel',
          },
          href: externalLinks.youtube,
        },
        {
          icon: {
            src: require('assets/icons/linkedin.svg'),
            alt: 'Linkedin profile',
          },
          href: externalLinks.linkedin,
        },
        {
          icon: {
            src: require('assets/icons/xing.svg'),
            alt: 'Xing profile',
          },
          href: externalLinks.xing,
        },
      ]}
      mobileAppsLinks={[
        {
          icon: {
            src: require('assets/images/apple-store.png'),
            alt: 'ios app',
          },
          href: externalLinks.ios_app,
        },
        {
          icon: {
            src: require('assets/images/google-play-store.png'),
            alt: 'android app',
          },
          href: externalLinks.android_app,
        },
      ]}
      subFooterContent={{
        legalLinks: [
          {
            text: 'Impressum',
            href: externalLinks.impressum,
          },
          {
            text: 'Datenschutz',
            href: externalLinks.datenschutz,
          },
        ],
        copyrightText: `Alle Rechte vorbehalten © HSE ${new Date().getFullYear()}`,
      }}
    />

	</div>
);

export default DisclaimerPage;
